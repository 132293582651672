import React, { Suspense } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { ConfigProvider, Layout, Spin } from 'antd';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const Sidebar = React.lazy(() => import('./components/Sidebar'));
const CampaignListPage = React.lazy(
  () => import('./pages/campaign/CampaignListPage')
);
const CampaignDetailPage = React.lazy(
  () => import('./pages/campaign/CampaignDetailPage')
);
const CreateCampaignPage = React.lazy(
  () => import('./pages/campaign/CreateCampaignPage')
);
const UpdateCampaignPage = React.lazy(
  () => import('./pages/campaign/UpdateCampaignPage')
);
const BriefFormPage = React.lazy(
  () => import('./pages/campaign/BriefFormPage')
);
const CustomerListPage = React.lazy(
  () => import('./pages/customer/CustomerListPage')
);
const CustomerDetailPage = React.lazy(
  () => import('./pages/customer/CustomerDetailPage')
);
const CreateCustomerPage = React.lazy(
  () => import('./pages/customer/CreateCustomerPage')
);
const UpdateCustomerPage = React.lazy(
  () => import('./pages/customer/UpdateCustomerPage')
);
const UserDetailPage = React.lazy(() => import('./pages/user/UserDetailPage'));
const CreateUserPage = React.lazy(() => import('./pages/user/CreateUserPage'));
const UpdateUserPage = React.lazy(() => import('./pages/user/UpdateUserPage'));
const OrderDetailPage = React.lazy(
  () => import('./pages/order/OrderDetailPage')
);
const UpdateOrderPage = React.lazy(
  () => import('./pages/order/UpdateOrderPage')
);
const LoginPage = React.lazy(() => import('./pages/login/LoginPage'));
const TwitterCallbackPage = React.lazy(
  () => import('./pages/login/TwitterCallbackPage')
);
const LeadListPage = React.lazy(() => import('./pages/lead/LeadListPage'));
const ShortUrlsPage = React.lazy(
  () => import('./pages/shorturls/ShortUrlsPage')
);
const ProductManagment = React.lazy(
  () => import('./pages/product/ProductManagment')
);
const ProductCreate = React.lazy(() => import('./pages/product/ProductCreate'));
const queryClient = new QueryClient();

const { Content } = Layout;

const getShouldShowSidebar = (path: string) => {
  const hiddenSidebarRoutes = ['/auth', '/auth/twitter/callback'];
  let shouldShowSidebar = !hiddenSidebarRoutes.includes(path);

  if (path.indexOf('/campaigns/Brief/') > -1) {
    shouldShowSidebar = false;
  }

  return shouldShowSidebar;
};

const App: React.FC = () => {
  const location = useLocation();
  const shouldShowSidebar = getShouldShowSidebar(location.pathname);

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#5b3de9',
            colorInfo: '#5b3de9',
            colorError: '#f05331',
            colorTextDisabled: '#60646C',
            borderRadius: 8
          },
          components: {
            Table: {
              headerColor: '#6B7280'
            },
            Alert: {
              colorSuccessBg: 'rgb(245,251,245)',
              colorSuccessBorder: 'rgb(232,247,232)'
            }
          }
        }}
      >
        <Layout style={{ height: '100vh', backgroundColor: '#F9F9FB' }}>
          {shouldShowSidebar && <Sidebar />}
          <Layout>
            <Content
              style={{
                backgroundColor: '#F9F9FB',
                overflow: 'auto',
                height: '100vh'
              }}
            >
              <Suspense
                fallback={
                  <Spin className="w-full h-1/2 flex justify-center items-center" />
                }
              >
                <Routes>
                  <Route path="/" element={<Navigate to="/campaigns" />} />
                  <Route path="/campaigns" element={<CampaignListPage />} />
                  <Route
                    path="/campaigns/new"
                    element={<CreateCampaignPage />}
                  />
                  <Route
                    path="/campaigns/:id/:tabId?"
                    element={<CampaignDetailPage />}
                  />
                  <Route
                    path="/campaigns/:campaignId/orders/:orderId"
                    element={<OrderDetailPage />}
                  />
                  <Route
                    path="/campaigns/edit/:id"
                    element={<UpdateCampaignPage />}
                  />
                  <Route
                    path="/campaigns/Brief/:id"
                    element={<BriefFormPage />}
                  />

                  <Route path="/customers" element={<CustomerListPage />} />
                  <Route
                    path="/customers/new"
                    element={<CreateCustomerPage />}
                  />
                  <Route
                    path="/customers/:id"
                    element={<CustomerDetailPage />}
                  />
                  <Route
                    path="/customers/edit/:id"
                    element={<UpdateCustomerPage />}
                  />

                  <Route
                    path="/orders/:orderId"
                    element={<OrderDetailPage />}
                  />
                  <Route
                    path="/orders/edit/:orderId"
                    element={<UpdateOrderPage />}
                  />
                  <Route path="/products" element={<ProductManagment />} />
                  <Route path="/products/new" element={<ProductCreate />} />
                  <Route path="/products/edit" element={<ProductCreate />} />

                  <Route path="/users/new" element={<CreateUserPage />} />
                  <Route path="/users/:id" element={<UserDetailPage />} />
                  <Route path="/users/edit/:id" element={<UpdateUserPage />} />

                  <Route path="/leads" element={<LeadListPage />} />

                  <Route path="/shorturls" element={<ShortUrlsPage />} />

                  <Route path="/auth" element={<LoginPage />} />
                  <Route
                    path="/auth/twitter/callback"
                    element={<TwitterCallbackPage />}
                  />
                </Routes>
              </Suspense>
            </Content>
          </Layout>
        </Layout>
      </ConfigProvider>
    </QueryClientProvider>
  );
};

export default App;
