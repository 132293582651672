export const TEST_API_BASE_URL = 'https://api.test.ahalab.io/';
export const PRE_API_BASE_URL = 'https://api.pre.ahalab.io/';
export const BASE_URL = 'https://api.ahalab.io/';

// Define a type alias for environment names
type Environment = 'production' | 'pre' | 'staging';

interface Config {
  BASE_URL: string;
  applicationId: string;
  clientToken: string;
  env: Environment;
}

// staging 为测试环境。

const environmentConfig: Record<Environment, Config> = {
  production: {
    BASE_URL: BASE_URL,
    // 观测云 prod 工作空间
    applicationId: '21a38810_643e_11ef_92a9_af8825f18f04',
    clientToken: 'c0eef250570e44b5b4f1e5830084305c',
    env: 'production'
  },
  pre: {
    BASE_URL: PRE_API_BASE_URL,
    // 观测云 pre 工作空间
    applicationId: '',
    clientToken: '',
    env: 'pre'
  },
  staging: {
    BASE_URL: TEST_API_BASE_URL,
    // 观测云 test 工作空间，后会改名为 staging
    applicationId: '6cffd960_642c_11ef_a7de_9bfd9a87b97f',
    clientToken: '7a8ffde57457428ebb392dce4f58714f',
    env: 'staging'
  }
};

const env = (process.env.REACT_APP_ENV as Environment) || 'staging';
const appConfig = environmentConfig[env];

export default appConfig;
